import { useState } from "react";
import { createRoot } from "react-dom/client";

import { ILightboxImage, Lightbox } from "Components/Lightbox";

interface IImageGalleryProps {
	images: ILightboxImage[];
	lightboxElement: HTMLElement;
}

interface IImageGalleryState {
	currentImage: number;
	isOpen: boolean;
}

function ImageGallery(props: IImageGalleryProps) {
	const [state, setState] = useState<IImageGalleryState>({ currentImage: 0, isOpen: false });

	function onOpen(e: React.MouseEvent<HTMLElement>) {
		setState(prev => ({ ...prev, isOpen: true }));
		e.preventDefault();
	}

	function getNext(prev: IImageGalleryState, step: number) {
		const max = props.images.length - 1;
		const next = prev.currentImage + step;
		if (next < 0) {
			return max;
		}

		if (next > max) {
			return 0;
		}

		return next;
	}

	const image = props.images[0];

	return (
		<>
			<a href={image.src} className="d-inline-block" onClick={onOpen}>
				<img src={image.src} className="img-thumbnail" alt="Screenshot of Screamer Radio" />
			</a>
			{state.isOpen &&
				<Lightbox
					description={`${state.currentImage + 1} of ${props.images.length}`}
					element={props.lightboxElement}
					image={props.images[state.currentImage]}
					next={() => setState(prev => ({ ...prev, currentImage: getNext(prev, 1) }))}
					prev={() => setState(prev => ({ ...prev, currentImage: getNext(prev, -1) }))}
					close={() => setState(prev => ({ ...prev, isOpen: false }))}
				/>
			}
		</>
	);
}

function renderGallery(gallery: Element, lightbox: HTMLElement) {
	const images = JSON.parse(gallery.getAttribute("images") ?? "[]") as string[];
	const galleryImages = images.map(i => ({ src: i })) as ILightboxImage[];
	const root = createRoot(gallery);

	root.render(<ImageGallery images={galleryImages} lightboxElement={lightbox} />);
}

const lightboxElement = document.getElementById("lightbox");

if (lightboxElement) {
	for (const lightboxGallery of document.getElementsByClassName("lightbox-gallery")) {
		renderGallery(lightboxGallery, lightboxElement);
	}
}
