import { useEffect } from "react";

export function useKeyboard(event: "keydown" | "keypress" | "keyup", callback: (e: KeyboardEvent) => void) {
	useEffect(() => {
		function handler(e: KeyboardEvent) {
			callback(e);
		}

		document.addEventListener(event, handler);

		return () => {
			document.removeEventListener(event, handler);
		};
	}, [callback, event]);
}
