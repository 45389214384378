import { createPortal } from "react-dom";

import { useKeyboard } from "Hooks/Keyboard";

export interface ILightboxImage {
	src: string;
}

export interface ILightboxProps {
	description: string;
	element: HTMLElement;
	image: ILightboxImage;

	close: () => void;
	next: () => void;
	prev: () => void;
}

export function Lightbox(props: ILightboxProps) {
	useKeyboard("keyup", (e: KeyboardEvent) => {
		switch (e.key) {
			case "Escape":
				props.close();
				break;
			case "LeftArrow":
				props.prev();
				break;
			case "RightArrow":
				props.next();
				break;
		}
	});

	const content = (
		<div className="lightbox" onClick={e => { props.close(); e.stopPropagation(); }}>
			<div className="lightbox-backdrop"></div>
			<div className="lightbox-container">
				<div className="lightbox-content" onClick={e => e.stopPropagation()}>
					<img src={props.image.src} />
				</div>
				<p className="lightbox-description">{props.description}</p>
			</div>
			<button className="btn btn-link lightbox-back" onClick={e => { props.prev(); e.stopPropagation(); }}>
				<span className="bi bi-chevron-left fs-2 text-white"></span>
				<span className="d-none">Previous</span>
			</button>
			<button className="btn btn-link lightbox-forward" onClick={e => { props.next(); e.stopPropagation(); }}>
				<span className="bi bi-chevron-right fs-2 text-white"></span>
				<span className="d-none">Next</span>
			</button>
		</div>
	);

	return createPortal(content, props.element);
}
